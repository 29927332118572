import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('BaseBreadcrumb',{attrs:{"title":_vm.page.title,"module_name":_vm.page.name}}),(_vm.show_metabase)?[_c(VRow,[_c(VCol,[_c(VCard,[_c(VToolbar,{attrs:{"dense":"","flat":""}},[(_vm.metabase_dashboards.length === 1)?_c(VToolbarTitle,[_vm._v(" "+_vm._s(_vm.metabase_dashboards[0].name)+" ")]):_c(VSelect,{staticStyle:{"width":"100px"},attrs:{"items":_vm.metabase_dashboards,"item-text":"name","item-value":"id","hide-details":"","single-line":"","dense":""},on:{"change":function($event){_vm.key = _vm.key + 1}},model:{value:(_vm.selected_dashboard),callback:function ($$v) {_vm.selected_dashboard=$$v},expression:"selected_dashboard"}}),_c(VSpacer),_c(VBtn,{attrs:{"text":"","small":""},on:{"click":_vm.toMetabase}},[_vm._v(" "+_vm._s(_vm.$store.getters.translate("to_metabase"))+" "),_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-arrow-right")])],1)],1),_c(VDivider),_c(VCardText),(_vm.selected_dashboard)?_c('div',{staticClass:"grid-col-12 iframe-holder",staticStyle:{"height":"76vh"}},[_c('iframe',{key:_vm.key,ref:"iframe",staticStyle:{"position":"relative","width":"100%","border":"none","overflow-y":"auto","overflow-x":"hidden","height":"100%","padding-left":"10px","padding-right":"10px","background":"white"},attrs:{"src":_vm.iframe_url,"name":"bi","scrolling":"yes","frameborder":"0","marginheight":"10px","marginwidth":"10px","width":"100%","allowfullscreen":"","allowtransparency":""}})]):_vm._e()],1)],1)],1)]:_c(VContainer,{attrs:{"fill-height":"","fluid":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{staticClass:"text-center"},[_c(VIcon,{attrs:{"color":"primary","x-large":""}},[_vm._v("mdi-power-plug-off")]),_c('h4',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.$store.getters.translate("metabase_configuration_incorrect"))+" ")])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }